import '../assets/fomantic/dist/semantic.css';
import { Container, Icon, Segment, Table, Image, Grid, GridColumn, Header, List, Statistic, StatisticGroup, Divider, Button, Label, LabelDetail } from 'semantic-ui-react';
import { Link } from '@reach/router';
import React from 'react';
import { Helmet } from 'react-helmet';
import AdSense from 'react-adsense';
import ReactGA from 'react-ga4';


import '../App.css';
import './Gauntlet.css';
import { NamerCrew, getCrew, EventInfo, getEventInfo, CaptainRanks, getCaptainRanks, getCommendations, Commendation, FullEventInfo, getFullEventInfo, getLeaderboard, EventLeaderboard, getOngoingLeaderboard, PlayerRank, FleetRank, vanityCaptainNames, knownMacro } from '../services/NamerStats';
import { SearchableTable, ITableConfigRow } from './SearchableTable';
import { isMobile, isMobileOnly, isTablet, useMobileOrientation } from 'react-device-detect';
import { Footer } from './Footer';
import EventStats from './CrewStats/EventStats';
import { logEvent, rarityClass, showThisCrew, unityRichTextToHtml } from '../utils/utils';
import { getAssetURL } from '../App';
import { getEventTraitHeader, getFeatured, traitIcon } from './EventList';

export const getEventIcon = (type: string) => {
    switch (type) {
        case "Galaxy": return "gather_header_icon.png";
        case "Skirmish": return "event_skirmish_icon.png";
        case "Faction": return "event_faction_missions_icon.png";
        case "Expedition": return "event_expedition_icon.png";
        case "Voyage": return "voyages_voyage_icon.png";
        default: return "event_hybrid_icon.png";
    }
}

export const getEventHeader = (event: FullEventInfo | EventInfo, isMinimal: boolean, leaderboard?: EventLeaderboard) => { 
    return <div className={"eventHeader"+((leaderboard&&!isMinimal)?" eventHeader2":"")}>
        {leaderboard && leaderboard.players && !isMinimal && <div className="playerContainer"><Grid padded className="playerSection">
            <div className="playerHeader">Top captains</div>
            {leaderboard.players.slice(0,3).map((x,idx)=>
                <div className="playerPeek" key={idx}>
                    <div className={"playerAvatar"}>
                        <div className={"gtopcrew-image-b"} style={{  position: 'relative', display: 'inline-block' }}>
                            <div className={"shadow-b"} style={{ position: 'relative', display: 'inline-block' }}>
                                <img className={"gauntlet-image-c"} src={getAssetURL(x.avatar)} height={40} />
                            </div>
                        </div>
                    </div>
                    <div className={"playerName"}>
                        <Link className="captainLink" to={"/captain/"+(x.vanity??x.pid)}
                            style={{ fontWeight: 'bolder' }}
                            onClick={()=>logEvent("CaptainLink", "captains", x.name)}>
                            {x.name}
                        </Link>
                    </div>
                    <div className="playerFleet">
                        {x.fid && <Link className="captainLink" to={"/fleet/"+(x.fvanity??x.fid)}
                            onClick={()=>logEvent("FleetLink", "fleet", x.fname)}>
                            <div dangerouslySetInnerHTML={{__html:unityRichTextToHtml(x.fname)}}/>
                        </Link>}
                    </div>
                </div>)}
            </Grid></div>}
        <div className={"eventHeaderOverlap"+((leaderboard && !isMinimal)?" eventHeaderOverlap2":"")} style={(isMobileOnly||leaderboard)?{}:{width:'75%'}}>
            <Image className="boxShadow" centered src={getAssetURL(event.motd)}/>
            <div className={isMinimal?"eventTitleM":"eventTitle"}>
                {leaderboard && <div className="liveevent">
                    <div className="blob"/>
                    <span style={{marginLeft:'0.5em'}}>{' '}<b>LIVE EVENT</b></span>
                </div>}
                <h1 className="tng blueLinkColor eventName" style={isMinimal?{}:{lineHeight: '1.6vw'}}>{event.name}</h1>
                <div className="eventDate inline2">
                    <Image style={{height:'28px', marginRight:'4px', marginTop:'0px'}} centered src={getAssetURL(getEventIcon(event.type))}/>
                    {event.type} event
                    {!leaderboard && <span style={{marginLeft:'4px'}}>- {new Date(event.start).toLocaleDateString("en-US", {timeZone: 'UTC', month:"short", day:"2-digit", year:"numeric"})}</span>}
                </div>
                {!isMinimal && !leaderboard && <p>{event.description}</p>}
            </div>
        </div>
        {leaderboard && leaderboard.fleets && !isMinimal && <div className="playerContainer">
            <Grid padded className="playerSection">
                <Grid.Row className="fleetHeader">Top fleets</Grid.Row>
                {leaderboard.fleets.slice(0,3).map((x,idx)=>
                    <Grid.Row className="fleetName" key={"f"+idx}><span dangerouslySetInnerHTML={{__html:unityRichTextToHtml(x.name)}}/></Grid.Row>
                )}
            </Grid>
        </div>}
    </div>
}
export const Event = (props: {
    id: number;
    navigation: () => void;
}) => {
    let mobileOrientation = useMobileOrientation();
    let isOnlyMobile = isMobile && !isTablet && mobileOrientation.isPortrait;
    const isMinimal = isMobileOnly || (isTablet && mobileOrientation.isPortrait);
    const [event, setEvent] = React.useState<FullEventInfo|undefined>(undefined);
    const [leaderboard, setLeaderboard] = React.useState<EventLeaderboard|undefined>(undefined);
    const [liveEvent, setLiveEvent] = React.useState<boolean>(false);
    const [eventIcon, setEventIcon] = React.useState<boolean>(false);
    const [captainsActive, setCaptainsActive] = React.useState<boolean>(true);
    const [traitCount, setTraitCount] = React.useState<number[]>([]);
    const [variantCount, setVariantCount] = React.useState<number[]>([]);

    React.useEffect(() => {
        props.navigation();
        if (!event) {
            getFullEventInfo().then((data) => setEvent(data.find(x=>x.id==props.id)));
        }
    }, [])

    React.useEffect(() => {
        if (event && !leaderboard) {
            if (event.traits && event.traits.find(x=>x.length>3)) {
                setEventIcon(true);
            }
            getLeaderboard(props.id).then((data) => {
                if (data) setLeaderboard(data);
                else getOngoingLeaderboard().then((live)=>{
                    if (live && live.id == props.id) {
                        setLeaderboard(live);
                        setLiveEvent(true);
                    }
                })
            });
            const v = Array(event.variants?.length || 0).fill(0); 
            const t = Array(event.traits?.length || 0).fill(0);
            getCrew().then(allCrew=>{
                if (!allCrew) return;
                for (const crew of allCrew) {
                    if (event.traits) {
                        for (let i=0; i<event.traits.length; i++) {
                            if (crew.traits.some(x=>x==event.traits[i]) || crew.hiddenTraits.some(x=>x==event.traits[i])) {
                                t[i]++
                            }
                        }
                    }
                    if (event.variants) {
                        for (let i=0; i<event.variants.length; i++) {
                            if (crew.traits.some(x=>x==event.variants[i]) || crew.hiddenTraits.some(x=>x==event.variants[i])) {
                                v[i]++
                            }
                        }
                    }
                }
                setVariantCount(v);
                setTraitCount(t);
            });
        }
    }, [event]);

    if (event == undefined) {
        return <Container><div className="center ui active loader"/></Container>;
    }

    const pTableConfig: ITableConfigRow[] = isMobileOnly ? [
        { width: 3, column: 'name', title: 'Captain', secondaryColumn: 'score' },
        { width: 1, column: 'score', title: 'Score', class: 'row-header'},
    ] : [
        { width: 1, column: 'rank', title: 'Rank', class: 'row-header'},
        { width: 7, column: 'name', title: 'Captain', secondaryColumn: 'rank' },
        { width: 2, column: 'score', title: 'Score', secondaryColumn: 'rank', class: 'row-header'},
    ]

    const fTableConfig: ITableConfigRow[] = isMobileOnly ? [
        { width: 3, column: 'name', title: 'Fleet', secondaryColumn: 'score' },
        { width: 1, column: 'score', title: 'Score', class: 'row-header'},
    ] : [
        { width: 1, column: 'rank', title: 'Rank', class: 'row-header'},
        { width: 7, column: 'name', title: 'Fleet', secondaryColumn: 'rank' },
        { width: 2, column: 'score', title: 'Score', secondaryColumn: 'rank', class: 'row-header'},
    ]

  const renderPTableRow = (ranks: PlayerRank, idx: number) => {
    let macro: boolean = false; //ranks.macro;
    // macro = macro || ranks.vpPerHour > 450000 || (ranks.skirmishAvgPerHour < 50 && ranks.vpPerHour > 400000);
    // if (macro) {
    //     knownMacro.set(ranks.pid, true);
    // } else {
    //     macro = knownMacro.get(ranks.pid) ?? false;
    // }
    // if (!liveEvent) {
    //     macro = false;
    // }
    return (
        <React.Fragment>
            {!isMobileOnly && <Table.Cell style={{ textAlign: 'center' }}>
                <big><b>#{ranks.rank}</b></big>
            </Table.Cell>}
            <Table.Cell style={{maxWidth:'80px', height:'65px !important'}}>
                <div
                    style={{
                        display: 'grid',
                        gridTemplateColumns: '60px auto',
                        gridTemplateAreas: `'icon stats' 'icon description'`,
                        gridGap: '1px'
                    }}
                >
                    <div style={{ gridArea: 'icon' }}>
                        <img width={48} src={getAssetURL((!ranks.avatar || ranks.avatar == "" ? "fleet_battle_scenarios_practice_space_bg.png" : ranks.avatar))} />
                    </div>
                    <div style={{ gridArea: 'stats' }} className="hideOverflow">
                        <Link className="captainLink" to={"/captain/"+(ranks.vanity??ranks.pid)}
                            style={{ fontWeight: 'bolder', fontSize: '1.25em' }}
                            onClick={()=>logEvent("CaptainLink", "captains", ranks.name)}>
                            {ranks.name}
                        </Link>
                        {//<big><b>{ranks.name}</b></big>
                        }
                    </div>
                    {ranks.fname && <div style={{ gridArea: 'description' }} className="hideOverflow">
                        <Link className="captainLink" to={"/fleet/"+(ranks.fvanity??ranks.fid)} 
                            onClick={()=>logEvent("FleetLink", "fleet", ranks.fname)}>
                            <span dangerouslySetInnerHTML={{__html:unityRichTextToHtml(ranks.fname)}}/>
                        </Link>
                    </div>}
                </div>
                {ranks.skirmishAvgPerHour && <div>
                    <Label className="top-pair" horizontal size="small" basic>
                        {isOnlyMobile ? "VP" : "VP gain"}
                        <LabelDetail>
                            +{ranks.vpPerHour.toLocaleString()}
                        </LabelDetail>
                    </Label>
                    <Label className="top-pair" horizontal size="small" basic>
                        {isOnlyMobile ? "Est. skirm." : "Est. avg. skirmish"}
                        <LabelDetail>
                            {(Math.round(ranks.skirmishAvgPerHour*100)/100).toLocaleString()}s
                        </LabelDetail>
                    </Label>
                </div>}
            </Table.Cell>
            <Table.Cell textAlign='center' className="tier">
                {!isMobileOnly && macro && <React.Fragment>
                    <span><Icon color='orange' name='warning sign' size='small'/>
                        <small style={{ fontSize: '80%' }}>macro suspected</small>
                    </span>
                    <br/>
                </React.Fragment>}
                {isMobileOnly && <div className="captainRank">
                    {macro && <span>
                        <Icon color='orange' name='warning sign' size='small'/>
                            <small style={{ fontSize: '100%' }}>macro</small>
                        </span>}
                    <small style={{ fontSize: '100%' }}>
                        {ranks.score.toLocaleString()}
                    </small>
                    
                    <big><b>#{ranks.rank}</b></big>
                </div>}
                {!isMobileOnly && <span>
                    {ranks.score.toLocaleString()}
                </span>}     
            </Table.Cell>
        </React.Fragment>
    );
  }

  function showPlayer(ranks: PlayerRank, filters: any[], filterType: string, arena?: boolean): boolean {
    let result = true;  
    for (const filter of filters) {
        for (const segment of filter.textSegments) {
            segment.text = segment.text.toLowerCase();
            result = result && (ranks.name.toLowerCase().includes(segment.text) || (ranks.fname && ranks.fname.toLowerCase().includes(segment.text)));
        }
    }
    return result;
  }

  const renderFTableRow = (ranks: FleetRank, idx: number) => {
    return (
        <React.Fragment>
            {!isMobileOnly && <Table.Cell textAlign='center'>
                <big><b>#{ranks.rank}</b></big>
            </Table.Cell>}
            <Table.Cell style={{maxWidth:'80px', height:'65px'}}>
                <div>
                    <Link className="captainLink" to={"/fleet/"+(ranks.vanity??ranks.fid)}
                        style={{ fontWeight: 'bolder', fontSize: '1.25em' }}
                        onClick={()=>logEvent("FleetLink", "fleets", ranks.name)}>
                        <span dangerouslySetInnerHTML={{__html:unityRichTextToHtml(ranks.name)}}/>
                    </Link>
                </div>
            </Table.Cell>
            <Table.Cell textAlign='center' className="tier">
                {isMobileOnly && <div className="captainRank">
                    <small style={{ fontSize: '100%' }}>
                        {ranks.score.toLocaleString()}
                    </small>
                    <big><b>#{ranks.rank}</b></big>
                </div>}
                {!isMobileOnly && <span>{ranks.score.toLocaleString()}</span>}     
            </Table.Cell>
        </React.Fragment>
    );
  }

  function showFleet(ranks: FleetRank, filters: any[], filterType: string, arena?: boolean): boolean {
    let result = true;  
    for (const filter of filters) {
        for (const segment of filter.textSegments) {
            segment.text = segment.text.toLowerCase();
            result = result && ranks.name.toLowerCase().includes(segment.text);
        }
    }
    return result;
  }

  const title = event.name + " - The Big Book of Behold Advice";
  const link = "https://www.bigbook.app/event/" + props.id;
  const description = "Star Trek Timelines event description and featured crew with player and fleet leaderboards";

  return (
    <div>
        <Helmet>
            <title>{title}</title>
            <link rel="canonical" href={link} />
            <meta name="description" content={description}/>
            <meta property="og:url" content={link}/>
            <meta property="og:description" content={description}/>
            <meta name="twitter:url" content={link}/>
            <meta name="twitter:title" content={title}/>
            <meta name="twitter:description" content={description}/>
        </Helmet>

        {getEventHeader(event, isMinimal)}

        <br/>

        <div className="center">
            <Grid stackable relaxed centered>
                {isMobile && <Segment>
                    <p className="eventDesc">{event.description}</p>
                </Segment>}
                <List.Item className={isMobileOnly?"eventSectionPadded":"eventSection"}>
                    <List.Content>
                        <List.Header className="eventSectionH"><b>Featured crew</b></List.Header>
                        <Grid stackable padded centered>
                            {event.featured?.map((x,idx)=>getFeatured(x,idx,isMobileOnly))}
                        </Grid>
                    </List.Content>
                </List.Item>
                <List.Item className={isMobileOnly?"eventSectionPadded":"eventSection"}>
                    <List.Content>
                        <List.Header className="eventSectionH"><b>Rewards</b></List.Header>
                        <Grid stackable padded centered>
                            {event.rewards?.map((x,idx)=>getFeatured(x,idx,isMobileOnly))}
                        </Grid>
                    </List.Content>
                </List.Item>
            </Grid>
            {!isOnlyMobile && <Grid stackable relaxed centered>
                {event.traits && <List.Item className="eventSection2" >
                    <List.Content>
                        <List.Header className="eventSectionH"><b>Traits</b></List.Header>
                        <List.Description>
                            {event.traits?.map((trait,idx)=>{
                                let letter: string | undefined;
                                let nonbonus: boolean = false
                                if (trait === "tosbridge" || trait === "tos") {
                                    letter = "TOS"
                                } else if (trait === "dsc") {
                                    letter = "DIS"
                                } else if (trait === "ent") {
                                    letter = "ENT"
                                } else if (trait === "tng") {
                                    letter = "TNG"
                                } else if (trait === "voy") {
                                    letter = "VOY"
                                } else if (trait === "pic") {
                                    letter = "PIC"
                                } else if (trait === "ds9") {
                                    letter = "DS9"
                                } else if (trait === "low") {
                                    letter = "LDS"
                                } else if (trait === "vst") {
                                    letter = "VST"
                                } else if (trait === "tas") {
                                    letter = "TAS"
                                } else if (trait === "snw") {
                                    letter = "SNW"
                                } else if (trait === "original") {
                                    letter = "Original"
                                }
                                if (trait.length==7 && !isNaN(trait.substring(3, 6) as any)) {
                                    letter = "Custom"
                                }
                                if (letter) {
                                    return <Statistic key={"t"+idx} size='tiny'>
                                        <Statistic.Label style={{marginLeft:'0px'}}><Link className="blueLink" to={"/ratings/shuttle?search="+encodeURIComponent(trait)} onClick={()=>logEvent("SearchType", "trait", trait)}>{event.named_traits[idx]}</Link></Statistic.Label>
                                        <Statistic.Value>{traitCount.length>=idx?traitCount[idx]:""}</Statistic.Value>
                                    </Statistic>;
                                }
                                return <Statistic key={"t"+idx} size='tiny'>
                                    <Statistic.Label style={{marginLeft:'0px'}}><Link className="blueLink" to={"/ratings/shuttle?search="+encodeURIComponent(trait)} onClick={()=>logEvent("SearchType", "trait", trait)}>{event.named_traits[idx]}</Link></Statistic.Label>
                                    <Statistic.Value style={{margin:'auto'}}><div className="inline2"><Image style={{width:'28px',marginRight:'4px'}} src={traitIcon(trait)}/>{traitCount.length>=idx?traitCount[idx]:""}</div></Statistic.Value>
                                </Statistic>;
                            })}
                        </List.Description>
                    </List.Content>
                </List.Item>}
                {event.variants && <List.Item className="eventSection2" >
                    <List.Content>
                        <List.Header className="eventSectionH"><b>Variants</b></List.Header>
                        <List.Description>
                            {event.variants?.map((x,idx)=>
                                <Statistic key={"v"+idx} size='tiny'>
                                    <Statistic.Label style={{marginLeft:'0px'}}>
                                        <Link className="blueLink" to={"/ratings/shuttle?search="+encodeURIComponent(x)} onClick={()=>logEvent("SearchType", "variant", x)}>{x}</Link>
                                    </Statistic.Label>
                                    <Statistic.Value>{variantCount.length>=idx?variantCount[idx]:""}</Statistic.Value>
                                </Statistic>)}
                        </List.Description>
                    </List.Content>
                </List.Item>}
                {event.named_factions && <List.Item className="eventSection2" >
                    <List.Content>
                        <List.Header className="eventSectionH"><b>Factions</b></List.Header>
                        <List.Description>
                            {event.named_factions?.map((x,idx)=>
                                <Statistic key={"v"+idx}>
                                    <Statistic.Label style={{marginLeft:'0px'}}>{x}</Statistic.Label>
                                    <Statistic.Value style={{margin:'auto'}}><Image style={{width:'28px'}} src={getAssetURL("faction_"+event.factions[idx]+".png")}/></Statistic.Value>
                                </Statistic>)}
                        </List.Description>
                    </List.Content>
                </List.Item>}
            </Grid>}
        </div>
        {isOnlyMobile && <Segment className="eventSegment"><Grid centered stackable>
            {event.traits && <div className="eventSectionM">
                <big><b>Traits</b></big>
                {event.traits?.map((trait,idx)=>{
                    let letter: string | undefined;
                    let custom: boolean = false
                    if (trait === "tosbridge" || trait === "tos") {
                        letter = "TOS"
                    } else if (trait === "dsc") {
                        letter = "DIS"
                    } else if (trait === "ent") {
                        letter = "ENT"
                    } else if (trait === "tng") {
                        letter = "TNG"
                    } else if (trait === "voy") {
                        letter = "VOY"
                    } else if (trait === "pic") {
                        letter = "PIC"
                    } else if (trait === "ds9") {
                        letter = "DS9"
                    } else if (trait === "low") {
                        letter = "LDS"
                    } else if (trait === "vst") {
                        letter = "VST"
                    } else if (trait === "tas") {
                        letter = "TAS"
                    } else if (trait === "snw") {
                        letter = "SNW"
                    } else if (trait === "original") {
                        letter = "Original"
                    }
                    if (trait.length==7 && !isNaN(trait.substring(3, 6) as any)) {
                        custom = true
                    }
                    return <div className="inlineTrait" key={"t"+idx}>
                        {!letter && !custom && <Image className="inlineImg" src={traitIcon(trait)} />}
                        <Link className="blueLink inline2" to={"/ratings/shuttle?search="+encodeURIComponent(trait)} onClick={()=>logEvent("SearchType", "trait", trait)}><big style={{marginRight:'4px'}}>{variantCount[idx]}</big>{letter ?? event.named_traits[idx]}</Link>
                    </div>;
                })}
            </div>}
            {event.variants && <div className="eventSectionM">
                <big><b>Variants</b></big>
                {event.variants?.map((x,idx)=>
                    <div className="inlineTrait" key={"v"+idx}>
                        <Link className="blueLink inline2" to={"/ratings/shuttle?search="+encodeURIComponent(x)} onClick={()=>logEvent("SearchType", "variant", x)}><big style={{marginRight:'4px'}}>{variantCount[idx]}</big>{x}</Link>
                    </div>)}
            </div>}
            {event.named_factions && <div className="eventSectionM">
                <big><b>Factions</b></big>
                {event.named_factions?.map((x,idx)=>{
                    return <div className="inline2" key={"f"+idx}><Image style={{width:'28px',marginRight:'4px'}} src={getAssetURL("faction_"+event.factions[idx]+".png")}/>{x}</div>})}
            </div>}
        </Grid></Segment>}

        <div className="adslot">
            {(window as any).adsbygoogle?.loaded && <span className='adlabel'>Advertisement</span>}
            {isMobileOnly && <AdSense.Google
                className="ad"
                client='ca-pub-5995456634193629'
                slot='3544657679'
                style={{ display: 'block'}}
                format='auto'
                responsive='true'
            />}
            {!isMobileOnly && <AdSense.Google
                className="ad adfixed"
                client='ca-pub-5995456634193629'
                slot='3568708400'
                style={{ display: 'inline-block', width: '728px', height: '90px', border: '1px solid #444444'}}
                format='fixed'
            />}
        </div>

        {leaderboard?.players && <React.Fragment>
            <h2 className="center">Leaderboards</h2>
            {liveEvent && leaderboard.date && <div className="leaderboardDate" style={(liveEvent && leaderboard.event.type == "Skirmish")?{marginBottom:'0px'}:{}}>
                <span> as of {new Date(leaderboard.date).toLocaleTimeString()}{' '}</span>
                <div className="blob"/>
            </div>}
            {liveEvent && leaderboard.event.type == "Skirmish" && <div className="leaderboardDate center" style={{marginBottom:'3em'}}>
                <span><Icon name='info circle' inverted/>VP gain and estimated average skirmish length are calculated based on the past 1 hour data and the total active time within the same interval.</span>
            </div>}
            {isMobileOnly && <div className='leaderboardSelect'>
                <Button.Group>
                    <Button positive={captainsActive} onClick={()=>setCaptainsActive(true)}>Captains</Button>
                    <Button.Or />
                    <Button positive={!captainsActive} onClick={()=>setCaptainsActive(false)}>Fleets</Button>
                </Button.Group>
            </div>}
            <Grid stackable={true} centered>
                {leaderboard?.players && (!isOnlyMobile || captainsActive) && <div style={!isMobileOnly?{minWidth:'550px'}:{minWidth:window.innerWidth-10,paddingLeft:'1px',paddingRight:'1px',marginBottom:'1em'}}>
                    {!isMobileOnly && <h3 className="center">Captains</h3>}
                    <SearchableTable
                        id="pLeaderboard"
                        data={leaderboard?.players ?? []}
                        config={pTableConfig}
                        renderTableRow={(ranks, idx) => renderPTableRow(ranks, idx)}
                        filterRow={showPlayer}
                        showFilterOptions={false}
                        showSearchExplanation={false}
                        hideRarityFilter={true}
                        defaultPerPage={100}
                        clearSearch={true}
                        fullSearchBar={true}
                        mobileWidth={true}
                        hideFooter={true}
                    />
                </div>}
                {leaderboard?.fleets && (!isOnlyMobile || !captainsActive) && <div style={!isMobileOnly?{minWidth:'550px'}:{minWidth:window.innerWidth-10,paddingLeft:'1px',paddingRight:'1px'}}>
                    {!isMobileOnly && <h3 className="center">Fleets</h3>}
                    <SearchableTable
                        id="fLeaderboard"
                        data={leaderboard?.fleets ?? []}
                        config={fTableConfig}
                        renderTableRow={(ranks, idx) => renderFTableRow(ranks, idx)}
                        filterRow={showFleet}
                        showFilterOptions={false}
                        showSearchExplanation={false}
                        hideRarityFilter={true}
                        defaultPerPage={100}
                        clearSearch={true}
                        fullSearchBar={true}
                        mobileWidth={true}
                        hideFooter={true}
                    />
                </div>}
            </Grid>
        </React.Fragment>}
        <Footer/>
    </div>
  );
}

export default Event;
